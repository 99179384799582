
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 0C2.25 0 0 2.25 0 5C0 7.75 2.25 10 5 10C7.75 10 10 7.75 10 5C10 2.25 7.75 0 5 0ZM7 5.5H5.5V7C5.5 7.3 5.3 7.5 5 7.5C4.7 7.5 4.5 7.3 4.5 7V5.5H3C2.7 5.5 2.5 5.3 2.5 5C2.5 4.7 2.7 4.5 3 4.5H4.5V3C4.5 2.7 4.7 2.5 5 2.5C5.3 2.5 5.5 2.7 5.5 3V4.5H7C7.3 4.5 7.5 4.7 7.5 5C7.5 5.3 7.3 5.5 7 5.5Z" fill="white"/>
</svg>

  </template>

  <script>
  export default {
    name: 'PlusCircle',
    inheritAttrs: true,
  }
  </script>
